import React, { useEffect, useState } from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const OrderPage = ({location}) => {

  const [plan, setPlan] = useState();
  useEffect(() => {
    setPlan(new URLSearchParams(location.search).get('plan'));
  }, location)

  return (
    <div>
      <SEO 
        title="Order Form" 
        description="Get your new website hosting service today.
        With a 30 day completely FREE trial period. No credit card information needed."
    />
      <div className="sub-section-wrap">
            <MenuPage/>
      </div>

      <div className="py-12 mt-5">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <iframe 
                title="Order Form" 
                className="w-full h-screen" 
                loading="lazy" 
                src={`https://sheephostingbay.duoservers.com/hosting-order/?s_l=en&s_id=290832&plan=${plan}`} />
          </div>
          
      </div>

      <Subscribe/>
      <Footer/>
    </div>
  )

}


export default OrderPage
